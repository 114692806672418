/**
 * 向后端请求用户的菜单，动态生成路由
 */
import { listClinician } from '@/api/clinician/clinician'




const useClinicianStore = {
  namespaced:true,
  state: {
    //    data:0,
       dataSourse:[]
  },
  mutations:{
         editDataSourse(state , data){
               state.dataSourse = data
               
         }
  },
  actions: {
       getCilinicianListAction({commit} , data){
           listClinician(data).then(res =>{
                // this.dataSourse = res.data.records
                commit('editDataSourse' ,res.data.records )
                // console.log(this.dataSourse)

           })   
       }
  }
}

export default useClinicianStore
