import request from '@/utils/request'


// 查询临床计划列表
export function listClinician(query) {
  return request({
    url: '/clinician/clinician/list',
    method: 'get',
    params: query
  })
}

// 查询临床计划分页
export function pageClinician(query) {
  return request({
    url: '/clinician/clinician/page',
    method: 'get',
    params: query
  })
}

// 查询临床计划详细
export function getClinician(data) {
  return request({
    url: '/clinician/clinician/detail',
    method: 'get',
    params: data
  })
}

// 新增临床计划
export function addClinician(data) {
  return request({
    url: '/clinician/clinician/add',
    method: 'post',
    data: data
  })
}

// 修改临床计划
export function updateClinician(data) {
  return request({
    url: '/clinician/clinician/edit',
    method: 'post',
    data: data
  })
}

// 删除临床计划
export function delClinician(data) {
  return request({
    url: '/clinician/clinician/delete',
    method: 'post',
    data: data
  })
}
