<template>
  <!-- <a-dropdown placement="bottomRight"> -->
  <!-- <span class="ant-pro-account-avatar"> -->
  <!-- <a-avatar size="small" :src="avatar" class="antd-pro-global-header-index-avatar" />
      <span>{{ nickname }}</span> -->
  <a-button size="large" @click="handleLogout" class="btn">
    Logout
    <!-- <a-icon type="logout" class="icon"/> -->
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="right-from-bracket"
      class="svg-inline--fa fa-right-from-bracket fa-fw"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      width="22px"
      height="22px"
      style="margin-left:5px"
    >
      <path
        fill="currentColor"
        d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"
      ></path>
    </svg>
  </a-button>
  <!-- </span> -->
  <!-- <template v-slot:overlay>
      <a-menu class="ant-pro-drop-down menu" :selected-keys="[]">
        <a-menu-item v-if="menu" key="center" @click="handleToCenter">
          <a-icon type="user" />
          个人中心
        </a-menu-item>
        <a-menu-item v-if="menu" key="settings" @click="handleToSettings">
          <a-icon type="setting" />
          个人设置
        </a-menu-item>
        <a-menu-divider v-if="menu" />
        <a-menu-item key="logout" @click="handleLogout">
          <a-icon type="logout" />
          退出登录
        </a-menu-item>
      </a-menu>
    </template> -->
  <!-- </a-dropdown> -->
  <!-- <span v-else>
    <a-spin size="small" :style="{ marginLeft: 8, marginRight: 8 }" />
  </span> -->
</template>

<script>
import { Modal } from 'ant-design-vue'
import { mapGetters } from 'vuex'
// import LogoutIcon from './logoutIcon.vue'
export default {
  name: 'AvatarDropdown',
  props: {
    menu: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    // LogoutIcon
  },
  computed: {
    ...mapGetters(['avatar', 'nickname']),
  },
  methods: {
    handleToCenter() {
      this.$router.push({ path: '/account/center' })
    },
    handleToSettings() {
      this.$router.push({ path: '/account/settings' })
    },
    handleLogout(e) {
      // Modal.confirm({
      //   title: '提示',
      //   content: '确定注销并退出系统吗？',
      //   onOk: () => {
      //     return this.$store.dispatch('Logout').then(() => {
      //       location.href = '/index'
      //     })
      //   },
      //   onCancel() {},
      // })
      this.$store.dispatch('Logout').then(() => {
        location.href = '/index'
      })
    },
  },
}
</script>

<style lang="less" scoped>
.ant-pro-drop-down {
  /deep/ .action {
    margin-right: 8px;
  }
  /deep/ .ant-dropdown-menu-item {
    min-width: 160px;
  }
}

.btn {
  align-items: center;
  background-color: #7f62ad;
  border-color: #7f62ad;
  box-shadow: none !important;
  display: flex;
  // white-space: pre;
  text-align: center;
  color: #fff;
  width: 109px;
  height: 38px;
  line-height: 38px;
  border-radius: 0.25rem;
  font-weight: 500;
  font-size: 1rem;
  margin-right: 16px;
  .icon {
    color: #fff;
    font-weight: bold;
    margin-top: 3px;
  }
  &:hover {
    background-color: #654f88 !important;
    border-color: #654f88 !important;
  }
}
</style>
