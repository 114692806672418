// <<<<<<< HEAD
// import './index.less'

// import { Icon, Menu, Dropdown } from 'ant-design-vue'
// import { i18nRender } from '@/locales'
// import i18nMixin from '@/store/i18n-mixin'

// const locales = ['zh-CN', 'en-US','ko-KR']
// const languageLabels = {
//   'zh-CN': '简体中文',
//   'en-US': 'English',
//   'ko-KR': '한국어'

// }
// // eslint-disable-next-line
// const languageIcons = {
//   'zh-CN': '🇨🇳',
//   'en-US': '🇺🇸',
//   'ko-KR': 'ko'
// }

// const SelectLang = {
//   props: {
//     prefixCls: {
//       type: String,
//       default: 'ant-pro-drop-down'
//     }
//   },
//   name: 'SelectLang',
//   mixins: [i18nMixin],
//   render () {
//     const { prefixCls } = this
//     const changeLang = ({ key }) => {
//       console.log('切换语言为',key)
//       this.setLang(key)
//     }
//     const langMenu = (
//       <Menu class={['menu', 'ant-pro-header-menu']} selectedKeys={[this.currentLang]} onClick={changeLang}>
//         {locales.map(locale => (
//           <Menu.Item key={locale}>
//             <span role="img" aria-label={languageLabels[locale]}>
//               {languageIcons[locale]}
//             </span>{' '}
//             {languageLabels[locale]}
//           </Menu.Item>
//         ))}
//       </Menu>
//     )
//     return (
//       <Dropdown overlay={langMenu} placement="bottomRight">
//         <span class={prefixCls}>
//           <Icon type="global" title={i18nRender('navBar.lang')} />
//         </span>
//       </Dropdown>
//     )
//   }
// }

// export default SelectLang

import './index.less'

import { Icon, Menu, Dropdown } from 'ant-design-vue'
import { i18nRender } from '@/locales'
import i18nMixin from '@/store/i18n-mixin'

//const locales = ['zh-CN', 'en-US','ko-KR']
const locales = ['zh-CN', 'en-US']
const languageLabels = {
  'zh-CN': '简体中文',
  'en-US': 'English',
  //'ko-KR': '한국어'

}
// eslint-disable-next-line
const languageIcons = {
  'zh-CN': '🇨🇳',
  'en-US': '🇺🇸',
  //'ko-KR': 'ko'

}

const SelectLang = {
  props: {
    prefixCls: {
      type: String,
      default: 'ant-pro-drop-down'
    }
  },
  name: 'SelectLang',
  mixins: [i18nMixin],
  render () {
    const { prefixCls } = this
    const changeLang = ({ key }) => {
      console.log('切换语言为',key)
      this.setLang(key)
    }
    const langMenu = (
      <Menu class={['menu', 'ant-pro-header-menu']} selectedKeys={[this.currentLang]} onClick={changeLang}>
        {locales.map(locale => (
          <Menu.Item key={locale}>
            <span role="img" aria-label={languageLabels[locale]}>
              {languageIcons[locale]}
            </span>{' '}
            {languageLabels[locale]}
          </Menu.Item>
        ))}
      </Menu>
    )
    return (
      <Dropdown overlay={langMenu} placement="bottomRight">
        <span class={prefixCls}>
          <Icon type="global" title={i18nRender('navBar.lang')} />
        </span>
      </Dropdown>
    )
  }
}

export default SelectLang
