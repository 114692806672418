export default {
  'app.setting.pagestyle': 'Page style setting',
  'app.setting.pagestyle.light': 'Light style',
  'app.setting.pagestyle.dark': 'Dark style',
  'app.setting.pagestyle.realdark': 'RealDark style',
  'app.setting.themecolor': 'Theme Color',
  'app.setting.navigationmode': 'Navigation Mode',
  'app.setting.content-width': 'Content Width',
  'app.setting.fixedheader': 'Fixed Header',
  'app.setting.fixedsidebar': 'Fixed Sidebar',
  'app.setting.multitab': 'View multitab',
  'app.setting.sidemenu': 'Side Menu Layout',
  'app.setting.topmenu': 'Top Menu Layout',
  'app.setting.content-width.fixed': 'Fixed',
  'app.setting.content-width.fluid': 'Fluid',
  'app.setting.othersettings': 'Other Settings',
  'app.setting.weakmode': 'Weak Mode',
  'app.setting.copy': 'Copy Setting',
  'app.setting.loading': 'Loading theme',
  'app.setting.copyinfo': 'copy success，please replace defaultSettings in src/config/defaultSettings.js',
  'app.setting.production.hint': 'Setting panel shows in development environment only, please manually modify',
  'app.setting.themecolor.daybreak': 'Daybreak Blue',
  'app.setting.themecolor.dust': 'Dust Red',
  'app.setting.themecolor.volcano': 'Volcano',
  'app.setting.themecolor.sunset': 'Sunset Orange',
  'app.setting.themecolor.cyan': 'Cyan',
  'app.setting.themecolor.green': 'Polar Green',
  'app.setting.themecolor.geekblue': 'Geek Blue',
  'app.setting.themecolor.purple': 'Golden Purple',

  '系统用户': {
    '开始时间': 'start time',
    '结束时间': 'end time',
  },


  '通用': {
    '按钮': {
      '新增': 'Add',
      '确定': 'Ok',
      '审核': 'audit',
      '修改': 'edit',
      '复制': 'copy',
      '删除': 'remove',
      '关闭': 'remove',
      '导出': 'export',
      '选中导出': 'select export',
      '导入': 'import',
      '查询': 'fetch results',
      '重置': 'Clear filter',
      '详情': 'info',
      '上传': 'upload',
      '展开': 'Expand',
      '收起': 'Stow',
      '更多': 'more',
      '保存': 'save',
      '取消': 'cancel',
      '翻译': 'translation',
      '刷新缓存': 'flush cache',
      '下载': 'Download',
      '下载提示':'Downloading data, please wait'
    },
    '输入': {
      '请输入': 'please enter ',
      '请选择': 'please select ',
    },
    '文本': {
      '是': 'yes',
      '否': 'no',
      '启用': 'enable',
      '禁用': 'disable',
      '操作': 'Operation',
      '添加': 'add',
      '创建时间': 'create time',
      '排序': 'sort',
      '修改': 'edit',
      '确认删除所选中数据': 'Confirm deletion of selected data?',
      '删除成功': 'remove success',
      '确认取消': 'confirm cancel?',
      '取消成功': 'cancel success',
      '新增成功': 'add success',
      '新增失败':"Add failure",
      '修改成功': 'edit success',
      '上传成功': 'upload success',
      '共': 'total ',
      '条': ' record',
      '默认': 'default',
      '中等': 'medium',
      '紧凑': 'compact',
      '多语言配置': 'multi-Language configuration',
      '列展示排序': 'column display/sort',
      '列排序需拖动名称': 'Column sorting requires dragging name',
      '不能为空': ' not be empty',
      '不能小于0': 'not be zero',
      '语言': 'Language',
      '根节点': 'root',
      '创建开始时间': 'create start time',
      '创建结束时间': 'create end time',
      '是否确认导出': 'Confirm Export?',
      '此操作将导出当前条件下所有数据而非选中数据': 'This will export all data under the current condition instead of the selected data',
      '此操作将导出选中数据': 'This will export the selected data',
    },
  },
  '广告': {
    '位置': 'position',
    '跳转类型': 'jump type',
    '广告名称': 'name',
    '开始时间': 'start time',
    '结束时间': 'end time',
    '选择申请': 'select apply',
    '外链': 'outlink',
    '内容详情': 'info',
    '是否隐藏': 'is hidden',
    '展示时长': 'show times',
    '展示次数': 'show num',
    '点击次数': 'click num',
    '跳转次数': 'jump num',
    '封面图': 'cover img',
    '详情图': 'detail img',
  },

  '商品分类': {
    '商品分类': 'category',
    '分类名称': 'category name',
    '分类图片': 'category pic',
    '上级分类': 'parent',
    '分类等级': 'level',
    '是否显示': 'is visible',
    '是否首页展示': 'is home visible',
    '是否参与筛选': 'is filter',
    '创建时间': 'create time',
  },
  '商品标签': {
    '标签名称': 'label name',
    '图标': 'icon',
    '颜色': 'color',
    '状态': 'status',
    '创建时间': 'create time',
    '更新时间': 'update time',
    '备注': 'remark',
  },
  '商品': {
    '批量上架': 'batch on sale',
    '查看关联商品': 'show goods',
    '标签': 'label',
    '售卖区域': 'prod area',
    '批量下架': 'batch off sale',
    '批量复制': 'batch copy',
    '确认复制': 'confirm copy?',
    '复制成功': 'copy success',
    '选择明星': 'select star',
    '选择俱乐部': 'select club',
    '选择运费模板': 'select Freight template',
    '基础信息': 'Basic information',
    '标签信息': 'label information',
    '规格信息': 'Spec Information',
    '详情信息': 'goods info',
    '上架成功': 'on sale success',
    '下架成功': 'off sale success',
    '确认上架': 'confirm on sale?',
    '确认下架': 'confirm off sale?',
    '添加规格': 'add specification',
    '修改规格': 'edit specification',
    '商品类型': 'goods type',
    '商品': 'goods',
    '是否有海报筒': 'isHavePoster',
    '明星': 'star',
    '限购数': 'min buy num',
    '售卖开始时间': 'sale start time',
    '售卖结束时间': 'sale end time',
    '售卖结束日期': 'sale end data',
    '上架时间': 'on sale time',
    '是否多规格': 'is multiple spec',
    '是否允许合并购买': 'is allow Merger Purchase',
    '商品颜色': 'color',
    '商品详情': 'goods info',
    '市场价': 'market price',
    '成本价': 'cost price',
    '虚拟销量': 'virtual Sales num',
    '是否展示销量': 'is show sales num',
    '商品价': 'price',
    '商品编号': 'goods code',
    'SKU编号': 'sku code',
    'SKU名字': 'sku name',
    '规格名称': 'spec name',
    '商品名称': 'goods name',
    '商品重量': 'weight',
    '包装单位': 'unit',
    '商品主图': 'master picture',
    '商品轮播图': 'carousel graph',
    '商品分类': 'goods category',
    '商品关键词': 'keyword',
    '品牌': 'brand',
    '运费模板': 'Freight template',
    '商品价格': 'price',
    '活动价格': 'activity price',
    '秒杀价格': 'seckill price',
    '团购价格': 'group price',
    '会员价格': 'member price',
    '限购数量': 'max buy num',
    '商品库存': 'stock',
    '预警库存': '预警库存',
    '商品状态': 'goods status',
    '实际销量': 'sales num',
    '售卖范围': '售卖范围',
    '是否推荐': 'is_recommend',
    '是否新品': 'is new',
    '是否样品': '是否样品',
    '是否日历展示': 'is show calendar',
    '是否下单即锁单': 'is order lock',
    '是否精品': '是否精品',
    '是否免费领': '是否免费领',
    '是否限购': 'is max buy',
    '是否爆款': '是否爆款',
    '售后方式': 'refund type',
    '创建时间': 'create time',
    '规格': {
      '添加规格值': 'add spec value',
      '规格名称': 'spec name',
      '规格值': 'spec value',
      '是否可见': 'is show',
      '规格图片': 'spec picture',
      '是否有规格图片': 'is have picture',
    },
    'sku': {
      '名称': 'sku name',
      '编码': 'sku code',
      '价格': 'sku price',
      '活动价格': 'sku activity price',
      '库存': 'stock',
      '重量': 'weight',
      '图片': 'sku picture',
    },
  },
  '品牌': {
    '品牌名称': 'brand name',
    '品牌备注': 'remark',
    '品牌首字母': 'first word',
    'logo': 'logo',
    '创建时间': 'create time',
  },
  '明星分类': {
    '分类名称': 'category',
    '是否显示': 'is show',
    '创建时间': 'create time',
    '备注': 'remark',
  },
  '明星': {
    '明星名字': 'star name',
    '明星分类': 'category',
    '明星照片': 'star picture',
    '背景图': 'Background picture',
    '基础粉丝数': 'fans num',
    '网址': 'web url',
    '状态': 'status',
    '关注数': 'focus num',
    '明星介绍': 'info',
    '创建时间': 'create time',
    '备注': 'remark',
  },
  '明星俱乐部': {
    '俱乐部名字': 'club name',
    '是否通用': 'is general',
    '商品数': 'goods num',
    '创建时间': 'create time',
    '备注': 'remark',
  },

  '活动分类': {
    '分类名称': 'cate name',
    '状态': 'status',
    '排序': 'sort',
    '创建者': '创建者',
    '创建时间': 'create time',
    '更新时间': 'update time',
    '备注': 'remark',
  },
  '活动记录': {
    '活动ID': 'activity id',
    '商品最大购买数量': 'max buy num',
    '选择活动': 'select activity',
    '排行': 'rank',
    '是否上架': 'is onsale',
    '活动类型': 'activity type',
    '活动名称': 'activity name',
    '开始时间': 'start time',
    '结束时间': 'end time',
    '活动封面图': 'cover picture',
    '分享海报图': 'poster picture',
    '分享文案': 'poster text',
    '限制会员等级': '限制会员等级',
    '最多参与次数': 'Maximum',
    '活动详情': 'activity info',
    '状态': 'status',
    '是否展示到日历': 'is show calendar',
    '创建时间': 'create time',
    '活动参与区域': '活动参与区域'
  },
  '运费模板': {
    '计费方式': '计费方式'
  },
  '收货地址': {
    '应援地址': '应援地址',
    '收货区域': '收货区域',
    '收货人手机号': '收货人手机号',

  },
  '广告投放审核': {
    '联系电话': 'Contact Phone',
    '广告名称': 'Advertisement Name',
    '广告简介': 'Advertising Introduction',
    '广告活动开始时间': 'start time',
    '广告活动结束时间': 'end time',
    '审核状态': 'audit status',
    '审核时间': 'audit time',
    '姓名': 'name',
    '创建时间': 'create time',
    '原因': 'reason',
    '是否通过': 'is pass',

  },
  '订单取消原因': {
    '名称': 'Name',
    '类型': 'type',
    '创建时间': 'create time',
    '是否显示': 'is show',
  },

  '等级设置': {
    '会员等级名称': 'Level Name',
    '等级图片': 'level picture',
    '等级图标': 'icon',
    '会员等级排序': 'sort',
    '升级所需积分': 'upgrade score',
  },
  '商品足迹': {
    '商品名称': 'goods name',
    '商品主图': 'picture',
    '排序': 'sort',
    '搜索次数': 'search num',
    '创建时间': 'create time',
  },
  '订单发货记录': {
    '发货方式': 'delivery type',
    '发货人手机号': 'delivery mobile',
    '发货时间': 'delivery time',
    '发货记录编号': 'delivery id',
    '发货开始时间': 'delivery start time',
    '发货结束时间': 'delivery end time',


  },

  '销售订单': {
    '全部订单': 'all',
    '此操作将导出当所有待发货订单而非选中数据': 'This will export the data when all pending orders are not selected',
    '导出待发货': 'Export to be shipped',
    '导入发货单': 'Import Shipment Doc',
    '接单': 'orders',
    '接单成功': 'orders success',
    '物流公司': 'Import Shipment Doc',
    '物流单号': 'Logistics document number',
    '物流详情': 'Logistics Details',
    '寄件人手机号': 'Sender Mobile Number',
    '订单编号': 'Order Number',
    '订单来源': 'Order Source',
    '订单类型': 'Order type',
    '订单数量': 'Order Quantity',
    '订单信息': 'Order Information',
    '订单详情': 'Order Details',
    '三方订单号': 'Tripartite Order Number',
    '买家ID': 'Buyer id',
    '买家名称': 'Buyer Name',
    '买家信息': 'Buyer Information',
    '手机号或邮箱': 'phone or email',
    '订单状态': 'Order Status',
    '下单时间': 'Order time',
    '下单开始时间': '下单开始时间',
    '下单结束时间': '下单结束时间',
    '支付方式': 'Payment Method',
    '支付时间': 'Payment time',
    '退款状态': '退款状态',
    '商品数量': 'Quantity of goods',
    '商品金额': 'Commodity amount',
    '商品实付金额': 'pay amount',
    '优惠金额': 'discount amount',
    '订单金额': 'Order Amount',
    '运费金额': 'Freight amount',
    '运费实付': 'Freight paid',
    '用户备注': 'User Notes',
    '商品信息': 'Commodity Information',
    '活动信息': 'Activity Information',
    '收货地址信息': 'Receiving address information',
    '实付单价': 'Actual unit price',
    '数量': 'Quantity',
    '收货人姓名': 'Receiver Name',
    '性别': 'sex',
    '手机号': 'mobile',
    '邮箱': 'email',
    '地址类型': 'address type',
    '地区': 'nation',
    '详细地址': 'address detail',
    '发货': 'receive',
    '发货成功': 'success',
    '取消订单': 'cancel',
    '锁单时间': 'lock time',
    '发货备注': 'remark',

    '订单原价': 'Original order price',
    '实付金额': 'Actual amount paid',
    '运费': 'Freight',
    '是否首单': 'is first order',
    '是否催发货': 'is remind',
    '应募信息': 'recruitment information',

  },
  '售后订单': {
    '原订单编号': 'Original Order Number',
    '售后单编号': 'After-sales order number',
    '售后类型': 'After-sales type',
    '售后状态': 'After-sales status',
    '售后凭证': 'After-sales Voucher',
    '购买数量': 'Purchase Quantity',
    '售后数量': 'After-sales quantity',
    '申请开始时间': 'Application start time',
    '申请结束时间': 'Application end time',
    '退款金额': 'Refund amount',
    '申请时间': 'Application time',
    '售后原因': 'After-sales reasons',
    '售后信息': 'After-sales information',
    '原订单信息': 'Original Order Information',
    '订单详情': 'Order Details',
    '售后详情': 'After-sales details',
    '售后商品': 'After-sale goods',
    '备注': 'Remarks',
    '审核': '审核',
    '是否同意': '是否同意',
    '拒绝原因': '拒绝原因',
    '审核成功': '审核成功',
    '退款': 'refund',
    '是否确认退款': 'is refund',
    '退款成功': 'refund success',

  },

  '用户': {
    '用户ID': 'user id',
    '发送消息': 'send message',
    '手机号': 'mobile',
    '联系方式': 'Contact Information',
    '用户等级': 'level',
    '积分': 'score',
    '邮箱': 'email',
    '昵称': 'nick name',
    '头像': 'avatar',
    '微信': 'wechat',
    '真实姓名': 'real name',
    '身份证': 'idcard',
    '性别': 'sex',
    '生日': 'Birthday',
    '邀请码': '邀请码',
    '注册时间': 'create time',
    '用户状态': 'status',
  },

  '用户积分变动记录': {
    '订单编号': 'orderNo',
    '备注': 'remark',
    '变动的积分数': 'score',
    '变动前剩余积分': 'before score',
    '变动后剩余积分': 'after score',
  },

  '用户注销': {
    '注销原因': 'cancel reason',
    '审核状态': 'Audit Status',
    '审核失败原因': 'Failure Reason',
    '审核人': 'Reviewer',
    '审核时间': 'Review time',
    '注销时间': 'Logout time',
  },

  '用户搜索记录': {
    '类型': 'type',
    '搜索内容': 'Search Content',
    '搜索次数': 'Number of searches',
    '创建时间': 'create time',

  },

  '意见反馈': {
    '用户': 'user',
    '备注': 'remark',
    '处理状态': 'status',
    '联系方式': 'Contact',
    '反馈图片': 'picture',
    '处理': 'Process',
    '反馈类型': 'type',
    '反馈内容': 'content',
    '处理结果': 'result',
    '创建时间': 'create time',
  },

  '物流公司信息': {
    '编号': 'id',
    '公司名称': 'Company Name',
    '公司编码': 'Company Code',
    '公司类型': 'Company Type',
    '公司logo': 'logo',
    '是否选用': 'Whether selected',
    '排序': 'sort',
    '备注': 'remark',

  },
  '报表': {
    '商品': {
      '订单开始时间': 'start time',
      '订单结束时间': 'end time',
      '点击量': 'clicks num',
      '收藏量': 'collection num',
      '订单量': 'order count',
      '商品销量': 'sales num',
      '加购量': 'cart num',
      '商品销售额': 'goods amount',
      '订单销售金额': 'order amount',
      '销售金额': 'pay amount',
      '销售占比': 'pay amount rate',
      '退款占比': 'refund amount rate',
      '退款金额': 'refund amount',
      '退款单数': 'refund order count',
      '退款量': 'refund_num',
    },
    '用户': {
      '注册开始时间': 'start time',
      '注册结束时间': 'end time',
      '支付金额': 'pay amount',
      '最后下单时间': 'last order time',
      '购买商品量': 'goods count',
    },
  },

  '商品对账单': {
    '账单日期': 'bill date',
    '账单月份': 'bill month',
  },

  '交易单': {
    '交易单号': 'trade no',
    '订单编号': 'order no',
    '订单类型': 'order type',
    'ip': 'ip',
    '支付方式': 'pay type',
    '交易类型': 'trade type',
    '支付金额': 'pay amount',
    '账期金额': '账期金额',
    '手续费': 'fee',
    '支付信息': '支付信息',
    '三方订单号': 'out trade no',
    '三方支付流水号': 'out pay no',
    '支付状态': 'pay status',
    '交易完成时间': 'pay time',
    '创建时间': 'create time',
    '过期时间': 'expire time',
    '备注': 'remark',
  },
  '常见问题': {
    '常见问题标题': '常见问题标题',
    '常见问题简述': '常见问题简述',
    '问题分类': '问题分类',
    '常见问题内容': '常见问题内容',
    '显示顺序': '显示顺序',
    '状态': '状态',
    '备注': '备注',
    '创建人': '创建人',
    '插入时间': '插入时间',
    '修改人': '修改人',
    '修改时间': '修改时间',
    '版本号': '版本号',
  },
  '常见问题分类': {
    '主图': '主图',
    '标题': '标题',
    '显示顺序': '显示顺序',
    '备注': '备注',
    '创建人': '创建人',
    '插入时间': '插入时间',
    '修改人': '修改人',
    '修改时间': '修改时间',
    '版本号': '版本号',
  },


  '人员测试': {
    'ID': 'id',
    '板子序列号': 'Serial number',
    '针id': 'Fixture ID',
    '启动时间': 'Start Date',
    '偏置V高': 'Bias V High',
    '偏置V低': 'Bias V Low',
    'I高': 'I High',
    'I低': 'I Low',
    'I画': 'I Draw',
    '电压': 'Battery V',
    '是否抽检通过': 'P On',
    '创建者': '创建者',
    '': '',
    '更新人': '更新人',
    '': '',
    '租户ID，外键{tenant.id}': '租户ID，外键{tenant.id}',
    '备注': '备注',
    '乐观锁': '乐观锁',
    '是否删除': '是否删除',
    'PCBA 测试结果': 'PCBA Test Results',
    '程序设计站的结果。': 'Results from the Programming Station.',
    '报告': 'Report',
    '显示图表小部件': 'Show diagram widgets',
    '全部': 'All',
    '通过': 'Passed',
    '未通过': 'Failed',
    '备注':"remark",
    '校准值':'Calibration value',
    'fw版本号':'fw Version',
  },
  '导出': {
    '读数导出标题': 'Readings Export',
    '读数导出介紹': 'This export contains all recorded CGM raw values and the glucose concentration (SMBG), which were measured with the associated glucometer.',
    '导出按钮': 'Fetch results',
    '输入提示': 'Enter min. 4-digit serial number',
    '导出按钮': 'Fetch results',
    '输入提示': 'Enter min. 4-digit serial number',
    '序列号':'serialNumber',
    '记录时间':'introducedAt',
    'fw版本号':'fwVersion',
    '读取次数':'reading',
    '首次记录时间':'firstIntroducedAt',
    '最后一次记录时间':'lastIntroducedAt'
  },

  '首页': {
    'pcba当日测试结果': 'PCBA Test Results today',
    '科目': 'Subjects',
    '临床医生': 'Clinician',
    'PCBA': 'PCBA',
  },

  '算法运行': {
    '算法运行': 'Algorithm Runs',
    '算法运行描述': 'With this framework you can process raw data in the cloud infrastructure.',
    '新算法运行': 'New Algorithm Run',
    '算法运行比较': 'Algorithm Runs Compare',
    '清除': 'Clear filter',
    '名称': 'Name',
    '执行人': 'Executed By',
    'BGM': 'BGM',
    'MARD': 'MARD',
    '执行时间': 'Executed At',
    '控制': 'controls',
    '学习': 'studies',
    '过滤': 'filter',
    '模型': 'model',
    '开始时间': 'From (Executed At)',
    '结束时间': 'To (Executed At)',
    '搜索': 'search'
  },

  '学习模块': {
    '学习标题': 'Studies',
    '学习描述': 'Clinical Studies and Virtual Studies are listed here. Virtual Studies can be created and individual Sensors can be added to a Virtual Study. Clinical Studies cannot be modified.',
    '新的虚拟研究': 'New Virtual Study',
    '学习名称': 'Study Name',
    '类型': 'Type',
    '传感器': 'Sensor',
    '序列号': 'Serial number',
    '保存': 'Save',
    '取消': 'Cancel',
    '清空': 'Clear filter',
    '研究':'Studies',
    '添加传感器':'Add Sensor',
    '传感器数据':'Sensor Data',
    '创建时间':'createTime',
    '学习分组':'studyGroup'
  },

  '传感器模块':{
    '传感器标题':'Sensor Data',
    '传感器介绍':'Display all recorded CGM raw values and the glucose concentration (SMBG), which were measured with the associated glucometer.',
  },


  '临床医生': {
    '临床医生': 'Clinician',
    '在临床研究中监测受试者': 'Monitoring subjects during a clinical study',
    '添加主题': 'Add Subject',
    "对象ID": 'Subject id',
    '传感器': 'Sensor',
    "添加对象": 'Add Subject',
    '名称':'name',
    '数量':'num',
    '选择pcba':'Select Sensors',
    '添加pcba标题':'Add pcba',
  }
     
}



// '算法运行': {
//   '算法运行': 'Algorithm Runs',
//     '算法运行描述': 'With this framework you can process raw data in the cloud infrastructure.',
//       '新算法运行': 'New Algorithm Run',
//         '算法运行比较': 'Algorithm Runs Compare',
//           '清除': 'Clear filter',
//             '名称': 'Name',
//               '执行人': 'Executed By',
//                 'BGM': 'BGM',
//                   'MARD': 'MARD',
//                     '执行时间': 'Executed At',
//                       '控制': 'controls',
//                         '学习': 'studies',
//                           '过滤': 'filter',
//                             '模型': 'model',
//                               '开始时间': 'From (Executed At)',
//                                 '结束时间': 'To (Executed At)',
//     },

// '学习模块': {
//   '学习标题': 'Studies',
//     '学习描述': 'Clinical Studies and Virtual Studies are listed here. Virtual Studies can be created and individual Sensors can be added to a Virtual Study. Clinical Studies cannot be modified.',
//       '新的虚拟研究': 'New Virtual Study',
//         '学习名称': 'Study Name',
//           '类型': 'Type',
//             '传感器': 'Sensors',
//               '序列号': 'Serial number',
//                 '保存': 'Save',
//                   '取消': 'Cancel',
//                     '清空': 'Clear filter',
//   },

// '临床医生': {
//   '临床医生': 'Clinician',
//     '在临床研究中监测受试者': 'Monitoring subjects during a clinical study',
//       '添加主题': 'Add Subject',
//         "对象ID": 'Subject id',
//           '传感器': 'Sensor',
//             "添加对象": 'Add Subject',
//               '名称': 'Subject ID',
//                 '数量': 'Sensors',
//                   '选择pcba': 'Selected Sensors',
//                     '序列号': 'serialNumber',
//                       '针ID': 'fixtureId',
//                         '添加pcba标题': 'Add Sensor',
//     }
// }

